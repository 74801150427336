import { createElement } from 'react';
import { render } from 'react-dom';

export default class Configurators {
    constructor() {
        this.productFilterContainer = document.querySelector('.product-filter-js');

        if (this.productFilterContainer) {
            this.productFilter();
        }
    }

    productFilter() {
        const description = this.productFilterContainer.getAttribute('data-description'),
            intro = this.productFilterContainer.getAttribute('data-intro'),
            submit = this.productFilterContainer.getAttribute('data-submit'),
            introImg = this.productFilterContainer.getAttribute('data-intro-img');
        import('../react/pages/product-filter.jsx')
            .then(ProductFilter => {
                render(createElement(ProductFilter.default, { description, intro, submit, introImg }), this.productFilterContainer);
            });
    }
}